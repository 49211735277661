import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import { YOUTUBE_URL } from '../../../content/data/social'

export default function Home() {
    return <>
        <Layout
            seoTitle="Kubevious :: Youtube"
            seoDescription="Join our youtube channel to learn more about Kubevious"
        >
            <Helmet>
                <meta
                    httpEquiv="Refresh"
                    content={`0; url=${YOUTUBE_URL}`}
                />
            </Helmet>

            <p>
                redirecting to:
                <a href={YOUTUBE_URL}>
                {YOUTUBE_URL}
                </a>
            </p>
        </Layout>
    </>
}
